import ApiService from '@/services/ApiService.js';

export default {
    state: {
        colours: [],
    },

    getters: {
        colours: state => state.colours,
    },

    mutations: {

        /**
         * Set colours state
         *
         * @param {Object} state
         * @param {Object} payload
         */
        SET_COLOURS(state, payload) {
            state.colours = payload
        },


    },

    actions: {
        /**
         * Get Colous from database ctworker
         *
         * @param {Object} context
         *
         * @return {Promise}
         */
        getAllColours(context) {
            return new Promise((resolve, reject) => {
                ApiService.getAllColours()
                    .then((response) => {
                        context.commit('SET_COLOURS', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

    }
}