<template>
    <header>
        <div class="m-3">
            <b-nav align="right" tabs class="users-top-nav mt-3" v-if="this.$store.getters.auth">
                <b-nav-item active v-if="this.$route.name !== 'Welcome'">
                    <router-link :to="{name: 'Welcome'}" class="text-success">Inicio</router-link>
                </b-nav-item>
                <b-nav-item active v-if="this.$store.getters.auth">
                    <router-link to="" class="text-success">Hola {{this.$store.getters.username}}</router-link>
                </b-nav-item>
                <b-nav-item @click="logout()" active>Cerrar Sesión</b-nav-item>
            </b-nav>
            <b-nav align="right" tabs class="users-top-nav mt-3" v-else>
                <b-nav-item active>
                    <router-link :to="{ name: 'Login'}" class="text-success">Iniciar Sesión</router-link>
                </b-nav-item>
            </b-nav>
        </div>
    </header>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    name: 'HeaderComponent',
    methods:{
        ...mapActions(['doLogOut']),

        logout() {
            this.doLogOut()
                .then(() => {
                    this.$router.push({name: 'Welcome'})
                        .catch(() => {})
                })
                .catch((error) => {
                    error.response.status ? (
                        console.log('user not verified'),
                        alert('La sesión ya ha expirado')
                    ) : false
                })
        }
    },
    created() {
        console.log(process.env.VUE_APP_ENV)
    }
}
</script>
<style scoped>
    .users-top-nav{
        width: 100%;
    }

</style>