<template>
    <div>
        <br>
        <br>
        <router-link :to="{ name: 'Welcome'}" class="text-success login-title">
            <h1 class="text-center text-muted"><span class="text-success">Ct</span>Worker</h1>
        </router-link>
        <br>
        <b-card class="mx-auto login-div">
            <ValidationObserver ref="loginForm" v-slot="{ handleSubmit }">
                <b-form class="mb-3" @submit.prevent="handleSubmit(login)">
                <h2 class="mb-3">Iniciar Sesión</h2>
                <div class="my-auto d-block mt-5">
                    <ValidationProvider vid="username" name="username" rules="required">
                        <div class="input-field mt-3" slot-scope="{ valid, errors }">
                            <label for="username">Usuario</label>
                            <b-form-input id="username" :state="errors[0] ? false : (valid ? true : null)" v-model="username" autocomplete="on"></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider vid="password" name="password" rules="required">
                        <div class="input-field my-3" slot-scope="{ valid, errors }">
                            <label for="password">Contraseña</label>
                            <b-form-input id="password" :state="errors[0] ? false : (valid ? true : null)" v-model="password" type="password" autocomplete="on"></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                </div>
                <b-overlay :show="show" rounded="sm" class="login-overlay mx-auto">
                    <b-button type="submit" block class="my-5 mx-auto d-block" variant="success">Iniciar Sesión</b-button>
                </b-overlay>
            </b-form>
            </ValidationObserver>
        </b-card>
    </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    name: 'LoginComponent',
    data() {
        return {
            status: 'not_accepted',
            username: '',
            password: '',
            show: false
        }
    },
    methods: {
        ...mapActions(['doLogin']),

        /**
         * Make the login for the user
         *
         */
        login() {
            const USER_DATA = {
                username: this.username,
                password: this.password
            }
            this.doLogin(USER_DATA)
                .then((response) => {
                    this.$root.$emit('isLoggedIn', response.username)
                    this.$router.push({name: 'Welcome'})
                })
                .catch((error) => {
                    error.response.status === 404?(
                        this.$refs.loginForm.setErrors({
                            username: ['El usuario introducido no existe']
                        })
                    ):(
                        this.$refs.loginForm.setErrors({
                            password: ['La contraseña introducida no es correcta']
                        })
                    )
                })
        }
    }
}
</script>
<style scoped>
    .login-div {
        width: 350px;
        height: 440px;
    }
    .login-overlay {
        width: 118px;
        height:40px;
    }
</style>
