<template>
    <b-col col sm="6" md="5" lg="3">
        <b-card :title="title" v-for="(adress, index) in adress" :key="index">
            <h6>{{ adress.name + ' ' + adress.surname + ' (' + adress.customer + ')' }} </h6>
            <h6>{{ adress.adress }}</h6>
            <h6>{{ adress.city + ' (' + adress.region + ')' }}</h6>
            <h6>{{ adress.postal_code }}</h6>
            <h6>{{ adress.email }}</h6>
        </b-card>
        {{this.$store.getters.adressType}}
    </b-col>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    name: 'AdressCard',
    props: {
        adress: Array,
        title: String
    },
    methods: {
        ...mapActions(['getOrderDetails', 'getOrderInfo']),
    },

}
</script>
