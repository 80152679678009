import WelcomeComponent from "../components/WelcomeComponent.vue"
import LoginComponent from "../components/sessionComponents/LoginComponent.vue"
import OrdersComponent from "../components/orderComponents/OrdersComponent.vue"
import OrderDetailsComponent from "../components/orderComponents/OrderDetailsComponent.vue"
import PasswordHashComponent from "../components/PasswordHashComponent.vue"
import ProductSearchComponent from "../components/productComponents/ProductSearchComponent.vue"
import ProductStockTableComponent from "../components/productComponents/ProductStockTableComponent.vue"

export default [
    // dynamic segments start with a colon
    { path: '/', name: 'Welcome', component: WelcomeComponent },
    { path: '/create_hash', name: 'CreateHash', component: PasswordHashComponent },
    { path: '/login', name: 'Login', component: LoginComponent},
    { path: '/orders', name: 'Pedidos', component: OrdersComponent, meta: { requiresAuth: true }},
    { path: '/order/:order_number', name: 'OrderDetails', component: OrderDetailsComponent, meta: { requiresAuth: true }},
    { path: '/search/:product_search', name: 'ProductSearch', component: ProductSearchComponent, meta: { requiresAuth: true }},
    { path: '/product_stock/:season/:year/:brand', name: 'Stock', component: ProductStockTableComponent, meta: { requiresAuth: true }},

]
