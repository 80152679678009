<template>
    <div>
        <b-modal
            :id="`modal-info-${'mod' + productId}`"
            :title="'Mod. ' + productId"
            hide-footer
        >
            <b-row>
                <b-col cols="2">
                    <ValidationProvider vid="previousProductId" name="previousProductId" rules="">
                        <div class="input-field mt-3" slot-scope="{ valid, errors }">
                            <label for="previousProductId" class="pb-1">Modelo Anterior</label>
                            <b-form-input
                                id="previousProductId"
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-model="productInfo.mod_anterior"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                </b-col>
                <b-col cols="2">
                    <ValidationProvider vid="productId" name="productId" rules="">
                        <div class="input-field mt-3" slot-scope="{ valid, errors }">
                            <label for="productId" class="pb-1">Modelo Actual</label>
                            <b-form-input
                                id="productId"
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-model="productInfo.modelo"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                </b-col>
                <b-col>
                    <ValidationProvider vid="type" name="type" rules="required">
                        <div class="input-field mt-3" slot-scope="{ valid, errors }">
                            <label for="type" class="pb-1">Tipo</label>
                            <b-form-input
                                id="type"
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-model="productInfo.tipo"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                </b-col>
                <b-col>
                    <ValidationProvider vid="family" name="family" rules="">
                        <div class="input-field mt-3" slot-scope="{ valid, errors }">
                            <label for="family" class="pb-1">Familia</label>
                            <b-form-input
                                id="family"
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-model="productInfo.familia"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                </b-col>
                <b-col cols="4">
                    <ValidationProvider vid="sizes" name="sizes" rules="">
                        <div class="input-field mt-3" slot-scope="{ valid, errors }">
                            <label for="sizes" class="pb-1">Tallas</label>
                            <b-form-input
                                id="sizes"
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-model="productInfo.tallas"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider vid="description" name="description" rules="">
                        <div class="input-field mt-3" slot-scope="{ valid, errors }">
                            <label for="description" class="pb-1">Descripción</label>
                            <b-form-input
                                id="description"
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-model="productInfo.descripcion"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                </b-col>
                <b-col cols="8">
                    <ValidationProvider vid="notes" name="notes" rules="">
                        <div class="input-field mt-3" slot-scope="{ valid, errors }">
                            <label for="notes" class="pb-1">Notas</label>
                            <b-form-input
                                id="notes"
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-model="productInfo.descripcion2"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                </b-col>
                <b-col cols="2">
                    <ValidationProvider vid="season" name="season" rules="required">
                        <div class="input-field mt-3" slot-scope="{ valid, errors }">
                            <label for="season" class="pb-1">Temporada</label>
                            <b-form-input
                                id="season"
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-model="productInfo.temporada"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                </b-col>
                <b-col cols="2">
                    <ValidationProvider vid="year" name="year" rules="required">
                        <div class="input-field mt-3" slot-scope="{ valid, errors }">
                            <label for="year" class="pb-1">Año</label>
                            <b-form-input
                                id="year"
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-model="productInfo.año"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                </b-col>
                <b-col cols="3">
                    <b-card title="Marcas" class="mt-3">
                        <ValidationProvider vid="brand" name="brand" rules="required">
                            <div class="input-field mt-3" slot-scope="{ valid, errors }">
                                <label for="brand" class="pb-1">1º Marca</label>
                                <b-form-input
                                    id="brand"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    v-model="productInfo.marca"
                                ></b-form-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider vid="brand-2" name="brand-2" rules="">
                            <div class="input-field mt-3" slot-scope="{ valid, errors }">
                                <label for="brand-2" class="pb-1">2º Marca</label>
                                <b-form-input
                                    id="brand-2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    v-model="productInfo.marca2"
                                ></b-form-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider vid="brand-3" name="brand-3" rules="">
                            <div class="input-field mt-3" slot-scope="{ valid, errors }">
                                <label for="brand-3" class="pb-1">3º Marca</label>
                                <b-form-input
                                    id="brand-3"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    v-model="productInfo.marca3"
                                ></b-form-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </b-card>
                </b-col>
                <b-col cols="3">
                    <b-card title="Fabricantes" class="mt-3">
                        <ValidationProvider vid="manufacturer" name="manufacturer" rules="">
                            <div class="input-field mt-3" slot-scope="{ valid, errors }">
                                <label for="manufacturer" class="pb-1">1º Fabricante</label>
                                <b-form-input
                                    id="manufacturer"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    v-model="productInfo.fabricante1"
                                ></b-form-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider vid="manufacturer-2" name="manufacturer-2" rules="">
                            <div class="input-field mt-3" slot-scope="{ valid, errors }">
                                <label for="manufacturer-2" class="pb-1">2º Fabricante</label>
                                <b-form-input
                                    id="manufacturer-2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    v-model="productInfo.fabricante2"
                                ></b-form-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </b-card>
                </b-col>
                <b-col cols="3">
                    <b-card title="Fabricantes" class="mt-3">
                        <ValidationProvider vid="manufacturer" name="manufacturer" rules="">
                            <div class="input-field mt-3" slot-scope="{ valid, errors }">
                                <label for="manufacturer" class="pb-1">Precio</label>
                                <b-form-input
                                    id="manufacturer"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    v-model="productInfo.precio"
                                ></b-form-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider vid="manufacturer-2" name="manufacturer-2" rules="">
                            <div class="input-field mt-3" slot-scope="{ valid, errors }">
                                <label for="manufacturer-2" class="pb-1">Precio Rebaja</label>
                                <b-form-input
                                    id="manufacturer-2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    v-model="productInfo.precio_rebaja"
                                ></b-form-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </b-card>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'ProductInfoModalComponent',
    data() {
        return {
            productModel:this.productId,
            dismissCountDown: 7,
            dismissSecs: 7,
            show: false,
        }
    },

    components: {

    },

    props : {
        productId:Number,
        sizesType: String
    },

    computed: {
        ...mapGetters(['productInfo'])
    },

    methods: {
        ...mapActions(['updateProductInfo']),

        /**
         * Update stock product data
         *
         */
        updateData() {

            this.updateProductInfo()
        },
    },

    mounted() {
        console.log(this.productInfo)

    }
}
</script>