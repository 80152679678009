<template>
    <b-container>
        <h2 class="text-center mt-5 text-secondary">Pedidos</h2>
        <b-card bg-variant="light" class="mt-5">
            <b-list-group v-for="(info, Index) in orders" :key="Index">
                <b-list-group-item @click="$root.$emit('status', info.estado)">
                    <router-link
                        class="text-secondary"
                        :to="{ name: 'OrderDetails', params:{ order_number: info.num_pedido }}"
                    >Pedido nº 8300-{{ info.num_pedido }} |
                        <span>Cliente {{ info.num_cliente }}</span>  |
                        <span v-if="info.estado === 0" class="text-primary mx-auto">Preparándose</span>
                        <span v-else-if="info.estado === 1" class="text-info mx-auto">Enviado</span>
                        <span v-else-if="info.estado === 2" class="text-success mx-auto">Entregado</span>
                        <span v-else-if="info.estado === 3" class="text-danger mx-auto">Cancelado</span>
                        <span v-else-if="info.estado === 5" class="text-info mx-auto">Nuevo Pedido</span>
                        <span class="date"> | {{ info.fecha | moment(' D [de] MMMM Y')}}</span>
                    </router-link>
                </b-list-group-item>
            </b-list-group>
        </b-card>
    </b-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'OrdersComponent',
    computed: {
        ...mapGetters(['orders']),
    },

    methods: {
        ...mapActions(['getAllOrders']),
    },

    created() {
        this.getAllOrders()
    },
}
</script>
<style scoped>
.date {
    float: right!important
}
.status {
    margin-left: 25%!important
}
@media (min-width:0px) and (max-width: 769px) {
    .status {
        margin-left: 1%!important
    }
    .date {
        float: none!important
    }
}

</style>