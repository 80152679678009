<template>
  <div>
  <b-card
    :title="'Mod. ' + productId"
    :img-src="'https://ctworker.com/images/gallery_size/' + productId + '.jpg'"
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 20rem;"
    class="mb-2"
  >
    <b-card-text>
    {{ type }}
    </b-card-text>
    <b-link
      v-b-modal="`modal-stock-${'mod' + productId}`"
      @click="productStock()"
      class="card-link text-success">
      Stock
    </b-link>
    <b-link
      v-b-modal="`modal-info-${'mod' + productId}`"
      class="card-link text-success"
      @click="productInfo()">
      Info
    </b-link>
    <b-link href="#" class="card-link text-success">Colores</b-link>
  </b-card>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductComponent',

  props: {
    productId: Number,
    type: String,
  },

  computed: {
    ...mapGetters(['search'])
  },

  methods: {
    ...mapActions(['getProductStock', 'setProductInfo']),

    /**
     * get product stock
     *
     */
    productStock() {
      this.getProductStock(this.productId)
    },

    /**
     * get product information
     *
     */
    productInfo() {
      const info = this.search.find(item => this.productId === item.modelo);
      this.setProductInfo(info)
    },
  }
}
</script>