<template>
  <b-container fluid>
    <b-overlay :show="show" no-wrap>
    </b-overlay>
    <b-row class="my-5">
      <b-col cols="12" class="title-col-size">
        <MainTitleComponent class="title">
        </MainTitleComponent>
      </b-col>
      <b-col class="search-input-col-size">
        <InputSearchComponent class="search-input">
        </InputSearchComponent>
      </b-col>
    </b-row>
    <b-row class="my-5">
      <h5 class="text-center" v-if="search.length >= 1">
        Resultados de la busqueda: {{ searchWords !== '' ? searchWords : productSearch }}
      </h5>
      <h2 class="text-center my-5" v-else>
        Su búsqueda de la palabra {{ searchWords !== '' ? searchWords : productSearch }} no ha obtenido resultados,
        por favor asegurese de que ha escrito bien su búsqueda
      </h2>
    </b-row>
    <b-row>
      <b-col
        cols="12" sm="6" md="4" lg="3" xl="2"
        class="my-5 mx-auto"
        v-for="(info, Index) in search"
        :key="Index"
      >
        <ProductComponent
          class="mx-auto"
          :productId="info.modelo"
          :type="info.tipo"
        >
        {{ info.tallas }}
        </ProductComponent>
        <ProductStockModal
          :productId="info.modelo"
          :sizesType="info.tallas"
        >
        </ProductStockModal>
        <ProductInfoModal
          :productId="info.modelo"
        >
        </ProductInfoModal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ProductComponent from "@/components/productComponents/ProductComponent.vue"
import InputSearchComponent from "@/components/searchComponents/InputSearchComponent.vue"
import MainTitleComponent from "@/components/titleComponents/MainTitleComponent.vue"
import ProductStockModal from "@/components/productComponents/ProductStockModalComponent.vue"
import ProductInfoModal from "@/components/productComponents/ProductInfoModalComponent.vue"

export default {
  name: 'ProductSearchComponent',
  data() {
    return {
      productSearch: this.$route.params.product_search,
      show: false,
    }
  },

  components: {
    ProductComponent,
    InputSearchComponent,
    MainTitleComponent,
    ProductStockModal,
    ProductInfoModal
  },

  computed: {
    ...mapGetters(['search', 'searchWords']),
  },

  methods: {
    ...mapActions(['getProductsFromSearch']),
  },

  mounted() {
    this.$root.$on('loader', (boolean) => {
      this.show = boolean
    })
  },

  created() {
    this.show = true
    this.getProductsFromSearch(this.productSearch)
      .then(() => {
        this.show = false
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.search-input {
  float: left
}
.title-col-size {
  width: 200px
}
.search-input-col-size {
  width: 300px
}

@media (min-width:0px) and (max-width: 575px) {
  .title {
    text-align: center;
  }
  .title-col-size {
    width: 100%
  }
  .search-input-col-size {
    width: 100%
  }
}
</style>
