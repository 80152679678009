import ApiService from '@/services/ApiService.js';

export default {
    state: {
        productStock: [],
        productInfo: [],
        productColors: [],
        seasonProductStock: []
    },

    getters: {
        productStock: state => state.productStock,
        productInfo: state => state.productInfo,
        productColors: state => state.productColors,
        seasonProductStock: state => state.seasonProductStock
    },

    mutations: {

        /**
         * Give the state to productStock
         *
         * @param {Object} state
         * @param {Object} productStock
         */
        SET_PRODUCT_STOCK(state, productStock) {
            state.productStock = productStock
        },

        /**
         * Give the state to productStock
         *
         * @param {Object} state
         * @param {Object} productInfo
         */
        SET_PRODUCT_INFO(state, productInfo) {
            state.productInfo = productInfo
        },

        /**
         * Give the state to productStock
         *
         * @param {Object} state
         * @param {Object} productColors
         */
        SET_PRODUCT_COLORS(state, productColors) {
            state.productStock = productColors
        },

        /**
         * Update product stock
         *
         * @param {Object} state
         * @param {Object} product
         */
        UPDATE_PRODUCT_STOCK(state, product) {
            state.productStock['talla_' + product.size] = state.productStock.id_color == product.color ? product.quantity : false
        },

        /**
         * Update product stock
         *
         * @param {Object} state
         * @param {Object} stock
         */
        SET_SEASON_PRODUCT_STOCK(state, stock) {
            state.seasonProductStock = stock
        }
    },

    actions: {
        /**
         * Update data from order details
         *
         * @param {Object} context
         * @param {Number} product
         *
         * @return {Promise}
         */
        getProductStock(context, product) {
            return new Promise((resolve, reject) => {
                ApiService.getProductStock(product)
                    .then((response) => {
                        context.commit('SET_PRODUCT_STOCK', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        /**
         * Update stock in every size/color in vuex productStock
         *
         * @param {Object} context
         * @param {Object} payload
         *
         */
        updateProductStockVuex(context, payload) {
            context.commit('UPDATE_PRODUCT_STOCK', payload)
        },

        /**
         * Update stock in ctworker database
         *
         * @param {Object} context
         *
         * @return {Promise}
         */
         updateProductStock(context) {
            return new Promise((resolve, reject) => {
                ApiService.updateProductStock(context.state.productStock)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        /**
         * Add a colour to the product
         *
         * @param {Object} context
         * @param {Object} payload
         *
         * @return {Promise}
         */
        addColourToProductStock(context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.addColourToProductStock(payload)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error.response.data)
                    })
            })
        },

        /**
         * Add a colour to the product
         *
         * @param {Object} context
         * @param {Object} payload
         *
         * @return {Promise}
         */
        deleteColorFromProductStock(context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.deleteColorFromProductStock(payload)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error.response.data)
                    })
            })
        },

        /**
         * get the product information
         *
         * @param {Object} context
         * @param {Object} payload
         *
         */
        setProductInfo(context, payload) {
            context.commit('SET_PRODUCT_INFO', payload)
        },

        /**
         * get the product information
         *
         * @param {Object} context
         * @param {Object} payload
         *
         */
        updateProductInfo(context, payload) {
            context.commit('SET_PRODUCT_INFO', payload)
        },

        /**
         * get the product information
         *
         * @param {Object} context
         * @param {Object} payload
         *
         */
        getSeasonProductStock(context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.getSeasonProductStock(payload[0], payload[1], payload[2])
                    .then((response) => {
                        context.commit('SET_SEASON_PRODUCT_STOCK', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }
    }
}