<template>
    <router-link :to="{ name: 'Welcome'}" class="text-success">
        <h1 class="text-muted">
            <span class="text-success">
                Ct
            </span>
            Worker
        </h1>
    </router-link>
</template>
<script>
export default {
    name: 'MainTitleComponent',
    props: {
      title: String
    },
}
</script>

