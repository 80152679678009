<template>
  <div class="welcome-div">
    <router-link :to="{ name: 'Welcome'}" class="text-success">
        <h1 class="text-center text-muted title">
            <span class="text-success">
                Ct
            </span>
            Worker
        </h1>
    </router-link>
    <InputSearchComponent class="mt-3 mx-auto search-input">
    </InputSearchComponent>
  </div>
</template>
<script>
import InputSearchComponent from "@/components/searchComponents/InputSearchComponent.vue"

export default {
    name: 'WelcomeComponent',
    components: {
        InputSearchComponent,
    },
}
</script>
<style scoped>
.welcome-div {
    margin-top: 12%!important
}
.title {
    font-size: 4rem;
    font-weight: 800;
}
.title:active {
    transform: scale(0.9)!important;
    transition: 0.7s !important;
}
.search-input{
    width:600px!important;
}
@media (min-width:0px) and (max-width: 575px) {
    .title{
        font-size: 3rem;
        font-weight: 800;
    }
    .welcome-div{
        margin-top: 30%!important
    }
    .search-input{
        width: 95%!important;
    }
}
</style>
