<template>
  <b-container fluid>
    <h2 class="text-center my-5 border w-25 mx-auto">Pedido nº 8300-{{ this.$route.params.order_number }}</h2>
    <b-row class="justify-content-md-center mb-5">
      <AdressCardComponent :title="'Dirección de Facturación'" :adress="orderBillingAdress"></AdressCardComponent>
      <AdressCardComponent :title="'Dirección de Envío'" :adress="orderShippingAdress"></AdressCardComponent>
    </b-row>
    <div class="text-center mb-5">
      <span>Estado del Pedido: </span>
      <h3 v-if="orderStatus.value === 3 || orderStatus.value === 2" :class="`text-${orderStatus.color} mt-3`">Pedido {{ orderStatus.text }}</h3>
      <b-form-select v-else v-model="selected" :options="options" :class="`text-${orderStatus.color}`" value-field="value" text-field="text"  @change="setOrderStatus()">
        <b-form-select-option :value="null" disabled>{{ orderStatus.text }}</b-form-select-option>
      </b-form-select>
      <h6 class="date mt-3">Fecha: {{ orderInfo.fecha | moment(' D [de] MMMM Y') }}</h6>
    </div>
    <b-overlay :show="show" rounded="lg"  class="w-75 mx-auto text-success">
      <table class="table table-bordered mx-auto">
        <thead>
          <tr>
            <th scope="col">Modelo</th>
            <th scope="col">Color</th>
            <th scope="col">Temporada</th>
            <th scope="col">Tipo</th>
            <th scope="col">Única</th>
            <th scope="col">00 m/ 1 a</th>
            <th scope="col">0 m/ 2 a</th>
            <th scope="col">1 m/ 3 a</th>
            <th scope="col">3 m/ 4 a</th>
            <th scope="col">6 m/ 5 a</th>
            <th scope="col">9 m/ 6 a</th>
            <th scope="col">12 m/ 7 a</th>
            <th scope="col">18 m/ 8 a</th>
            <th scope="col">24 m/ 10 a</th>
            <th scope="col">36 m/ 12 a</th>
            <th scope="col">Precio </th>
            <th scope="col">Cantidad</th>
            <th scope="col">Subtotal</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody v-for="(info, Index) in orderDetails" :key="Index">
          <tr class="border-1" v-if="getOrderCosts(info.modelo, info.color).quantity === 0">
            <td>{{ info.modelo }}</td>
            <td>{{ info.color }}</td>
            <td>{{ info.season }}</td>
            <td>{{ info.description }}</td>
            <h3 class="text-danger deleted-product text-center">Sin stock</h3>
          </tr>
          <tr v-else-if="info.sizes === 0">
            <td>{{ info.modelo }}</td>
            <td>{{ info.color }}</td>
            <td>{{ info.season }}</td>
            <td>{{ info.description }}</td>
            <td>{{ info.talla_unica === 0 ? '' : info.talla_unica }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, 'unica', info.talla_unica)" class="w-100 border-0" v-model="info.talla_unica">--></td>
            <td>{{ info.talla_00 === 0 ? '' : info.talla_00 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '00', info.talla_00)" class="w-100 border-0" v-model="info.talla_00">--></td>
            <td>{{ info.talla_0 === 0 ? '' : info.talla_0 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '0', info.talla_0)" class="w-100 border-0" v-model="info.talla_0">--></td>
            <td>{{ info.talla_1 === 0 ? '' : info.talla_1 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '1', info.talla_1)" class="w-100 border-0" v-model="info.talla_1">--></td>
            <td>{{ info.talla_3 === 0 ? '' : info.talla_3 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '3', info.talla_3)" class="w-100 border-0" v-model="info.talla_3">--></td>
            <td>{{ info.talla_6 === 0 ? '' : info.talla_6 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '6', info.talla_6)" class="w-100 border-0" v-model="info.talla_6">--></td>
            <td>{{ info.talla_9 === 0 ? '' : info.talla_9 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '9', info.talla_9)" class="w-100 border-0" v-model="info.talla_9">--></td>
            <td>{{ info.talla_12 === 0 ? '' : info.talla_12 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '12', info.talla_12)" class="w-100 border-0" v-model="info.talla_12">--></td>
            <td>{{ info.talla_18 === 0 ? '' : info.talla_18 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '18', info.talla_18)" class="w-100 border-0" v-model="info.talla_18">--></td>
            <td>{{ info.talla_24 === 0 ? '' : info.talla_24 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '24', info.talla_24)" class="w-100 border-0" v-model="info.talla_24">--></td>
            <td>{{ info.talla_36 === 0 ? '' : info.talla_36 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '36', info.talla_36)" class="w-100 border-0" v-model="info.talla_36">--></td>
            <td class="price-input">{{ info.item_order_price }} €</td>
            <td>{{ getOrderCosts(info.modelo, info.color).quantity }}</td>
            <td class="subtotal-input">{{ getOrderCosts(info.modelo, info.color).subtotal.toFixed(2) }} €</td>
            <td>
              <b-button title="Delete" class="delete-button" @click="deleteProductColor(info.id)">
                <b-icon icon="trash-fill" aria-hidden="true" class="trash-icon"></b-icon>
              </b-button>
            </td>
          </tr>
          <tr v-else>
            <td>{{ info.modelo }} (años)</td>
            <td>{{ info.color }}</td>
            <td>{{ info.season }}</td>
            <td>{{ info.description }}</td>
            <td></td>
            <td>{{ info.talla_1 === 0 ? '' : info.talla_1 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '1', info.talla_1)" class="w-100 border-0" v-model="info.talla_1">--></td>
            <td>{{ info.talla_2 === 0 ? '' : info.talla_2 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '2', info.talla_2)" class="w-100 border-0" v-model="info.talla_2">--></td>
            <td>{{ info.talla_3 === 0 ? '' : info.talla_3 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '3', info.talla_3)" class="w-100 border-0" v-model="info.talla_3">--></td>
            <td>{{ info.talla_4 === 0 ? '' : info.talla_4 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '4', info.talla_4)" class="w-100 border-0" v-model="info.talla_4">--></td>
            <td>{{ info.talla_5 === 0 ? '' : info.talla_5 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '5', info.talla_5)" class="w-100 border-0" v-model="info.talla_5">--></td>
            <td>{{ info.talla_6 === 0 ? '' : info.talla_6 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '6', info.talla_6)" class="w-100 border-0" v-model="info.talla_6">--></td>
            <td>{{ info.talla_7 === 0 ? '' : info.talla_7 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '7', info.talla_7)" class="w-100 border-0" v-model="info.talla_7">--></td>
            <td>{{ info.talla_8 === 0 ? '' : info.talla_8 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '8', info.talla_8)" class="w-100 border-0" v-model="info.talla_8">--></td>
            <td>{{ info.talla_10 === 0 ? '' : info.talla_10 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '10', info.talla_10)" class="w-100 border-0" v-model="info.talla_10">--></td>
            <td>{{ info.talla_12 === 0 ? '' : info.talla_12 }}<!--<input @keyup.enter="updateOrder(info.modelo, info.color, '12', info.talla_12)" class="w-100 border-0" v-model="info.talla_12">--></td>
            <td class="price-input">{{ info.item_order_price }} €</td>
            <td>{{ getOrderCosts(info.modelo, info.color).quantity }}</td>
            <td class="subtotal-input">{{ getOrderCosts(info.modelo, info.color).subtotal.toFixed(2) }} €</td>
            <td>
              <b-button title="Delete" class="delete-button" @click="deleteProductColor(info.id)">
                <b-icon icon="trash-fill" aria-hidden="true" class="trash-icon"></b-icon>
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </b-overlay>
    <b-row>
      <b-col>
        <h5 class="text-center">Total(Sin IVA): {{ getOrderCosts(null).totalNoTax.toFixed(2) }} € </h5>
        <h5 class="text-center">Envío: {{ orderInfo.portes }} € </h5>
        <h5 class="text-center">Total + Envío:  {{ getOrderCosts(null).total.toFixed(2) }} € </h5>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AdressCardComponent from "../adressComponents/AdressCardComponent"

export default {
  name: 'OrderDetailsComponent',
  data() {
    return {
      show: false,
      selected: null,
      totalCost: null,
      options: [
        { value: 0, text: 'Preparándose'},
        { value: 1, text: 'Enviado'},
        { value: 2, text: 'Entregado'},
        { value: 3, text: 'Cancelado'},
      ]
    }
  },
  components: {
    AdressCardComponent,
  },

  computed: {
    ...mapGetters(['orderDetails' , 'orderInfo', 'orderStatus', 'orderBillingAdress', 'orderShippingAdress', 'sizes']),
  },

  methods: {
    ...mapActions(['getOrderDetails', 'getOrderInfo', 'getOrderBillingAdress', 'getOrderShippingAdress', 'updateOrderDetails',
    'updateOrderStatus', 'deleteOrderProductColor']),

    /**
     * Update the order details
     *
     * @param {Number} productId
     * @param {String} color
     * @param {String} size
     * @param {String} quantity
     *
     */
    updateOrder(productId, color, size, quantity) {
      let orderNumber = this.$route.params.order_number
      const ORDER_DATA = {
        orderNumber: orderNumber,
        productId: productId,
        color: color,
        size: size,
        quantity: quantity
      }
      this.show = true

      this.updateOrderDetails(ORDER_DATA)
        .then((response) => {
          this.show = false
          console.log(response.data)
        })
        .catch((error) => {
          this.show = false
          alert(error)
        })
    },

    /**
     * Update the order order status
     *
     */
    setOrderStatus() {
      const ORDER_STATUS = {
        orderNumber: this.$route.params.order_number,
        status: this.selected
      }
      this.updateOrderStatus(ORDER_STATUS)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          alert(error)
        })
    },

    /**
     * Get the subTotal costs of the order
     *
     * @param {Number} productRef
     *
     * @return {Array}
     */
    getOrderCosts(productRef, color) {
      let subtotal = 0
      let quantity = 0
      let total = 0
      let iva = 0.21 * total

      this.orderDetails.forEach((item) => { // recorremos el array de detalles del pedido
        this.sizes.forEach((size) => { // recorremos el array tallas para luego usarlas en el subtotal
          item['modelo'] === productRef && item['color'] === color ? (
            quantity += parseInt(item[`talla_${size}`]),
            subtotal += item[`talla_${size}`] * parseFloat(item.item_order_price)
          ) :
          total += item[`talla_${size}`] * parseFloat(item.item_order_price) // usaremos null como parámetro para poder obtener el total
        })
      })

      return {
        subtotal: subtotal,
        quantity: quantity,
        totalNoTax: total,
        total: total + iva + parseFloat(this.orderInfo.portes)
      }
    },

    /**
     * Update the order details
     *
     * @param {Number} productId
     * @param {String} color
     *
     */
    deleteProductColor(id) {
      this.deleteOrderProductColor(id)
        .then((response) => {
          let match = this.orderDetails.find(item => item.id === id)
          let index = this.orderDetails.indexOf(match)
          this.orderDetails.splice(index, 1)
          console.log(response)
        })
        .catch((error) => {
          alert(error)
        })
    },
  },

  created() {
    this.getOrderDetails(this.$route.params.order_number)
    this.getOrderInfo(this.$route.params.order_number)
    this.getOrderBillingAdress(this.$route.params.order_number)
    this.getOrderShippingAdress(this.$route.params.order_number)
  },
}
</script>
<style>
  .price-input {
    width: 5%;
  }

  .subtotal-input {
    width: 8%;
  }

  .deleted-product {
    width: 1000px;
    z-index: 20!important;
    position:absolute
  }

  .trash-icon {
    color: green;
    border: 0px
  }

  .trash-icon:hover {
    color: rgb(2, 53, 2)
  }

  .delete-button {
    border:none
  }

  .delete-button:hover {
    background-color: white;
  }

  .delete-button:focus {
    background-color: white;
  }

  .btn-secondary {
    background-color:white;
    border: none;
  }
</style>