import axios from 'axios'
import ApiService from '@/services/ApiService.js';

export default {
    state: {
        username: JSON.parse(localStorage.getItem('ctWorker.username')),
        auth: localStorage.getItem('state.Auth'),
        token: localStorage.getItem('ctWorker.jwt'),
    },

    getters: {
        username: state => state.username,
        auth: state => state.auth,
        token: state => state.token,
    },

    mutations: {

        /**
         * Do login for employee user and set the items for localStorage and the state for auth, username and token
         *
         * @param {Object} state
         * @param {Object} userInfo
         *
        */
        DO_LOGIN(state, userInfo) {
            localStorage.setItem('ctWorker.username', JSON.stringify(userInfo.user))
            localStorage.setItem('ctWorker.jwt', userInfo.token)
            localStorage.setItem('state.Auth', true)
            state.auth = true
            state.username = userInfo.user
            state.token = userInfo.token
        },

        /**
         * Do log out, clear localStorage and unset the state in auth, username, token 
         *
         * @param {Object} state
         *
        */
        DO_LOGOUT(state) {
            localStorage.clear()
            state.auth = false
            state.username = null
            state.token = null
            state.orders = []
        },
    },

    actions: {

        /**
         * Save a new or existing assortment
         *
         * @param {Object} context
         * @param {Object} userData
         *
         * @return {Promise}
         */
        doLogin(context, userData) {
            let username = userData.username
            let password = userData.password
            return new Promise((resolve, reject) => {
                ApiService.login({username, password})
                    .then((response) => {
                        context.commit('DO_LOGIN', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        /**
         * Log out from employee session
         *
         * @param {Object} context
         *
         * @return {Promise}
         */
        doLogOut(context) {
            return new Promise((resolve, reject) => {
                ApiService.logOut()
                .then((response) => {
                    context.commit('DO_LOGOUT')
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
            })
        },

        /**
         * Check the autentication in session
         *
         * @param {Object} context
         *
         * @return {Promise}
         */
        checkUserAuth(context) {
            JSON.parse(localStorage.getItem('ctWorker.username')) || JSON.parse(localStorage.getItem('ctWorker.jwt')) === null ?
                axios.post('checkUser')
                    .then(() => {
                        return console.log('user verified')
                    })
                    .catch((error) => {
                        return error.response.status ? context.commit('DO_LOGOUT') : false
                    }) : false
        },

        /**
         * Check the autentication in session
         *
         * @param {Object} context
         * @param {String} string
         *
         * @return {Promise}
        */
        stringEncryption(context, string) {
            return new Promise((resolve, reject) => {
                ApiService.generateBcrypPassword(string)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }
    }
}