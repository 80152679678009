<template>
    <div>
    <b-col>
        <b-icon icon="search" class="search-icon">
        </b-icon>
        <VueSimpleSuggest
            v-model="searchWords"
            :list="simpleSuggestionList"
            :filter-by-query="true"
        >
            <input @keyup.enter="makeSearch()">
        </VueSimpleSuggest>
    </b-col>
    <b-modal id="mi-modal" title="Modal para la palabra clave 'lista'">
      Contenido del modal...
    </b-modal>
    </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    name: 'InputSearchComponent',
    data() {
        return {
            searchWords: '',
            simpleSuggestionList: [
                'Pedidos',
                'CreateHash',
                'Stock'
                /*'Albums',
                'Clientes',
                'Fichas',
                'Fabricantes',
                'Contabilidad'*/
            ],
        }
    },
    methods: {
        ...mapActions(['getProductsFromSearch']),

        /**
         * Process the string from the input and make the search
         */
        makeSearch() {
            let searchWordsArray = this.searchWords.split(" ")
            this.$root.$emit('loader', true)

            // Check if the user is auth
            !this.$store.getters.auth || !this.$store.getters.username || !this.$store.getters.token ? (
                alert('Debe de iniciar sessión en el sistema para poder hacer busquedas'),
                this.$router.push('Login')
            ) :

            // stock conditional
            searchWordsArray[0] === 'Stock' ?
            this.$router.push({ name: 'Stock', params: {
                season: searchWordsArray[1],
                year: searchWordsArray[2],
                brand: searchWordsArray[3]}})
                .catch(()=> {}
            ) :

            // simplesuggestionlist conditional with one word
            this.simpleSuggestionList.find((item) => (item === this.searchWords)) ?
            this.$router.push({ name: this.searchWords })
                .catch(() => {}
            ) :

            this.$router.push({ name: 'ProductSearch', params: { product_search: this.searchWords }})
                .catch(() => {})

            this.getProductsFromSearch(this.searchWords)
                .then(() => {
                    this.$root.$emit('loader', false)
                })
        },
    }
}
</script>
<style>
.vue-simple-suggest.designed .input-wrapper input {
    border-radius: 180px!important;
    height: 48px;
    padding-left: 60px!important;
    padding-right: 30px!important;
    border: 1px solid #aaa !important;
}

.vue-simple-suggest.designed .input-wrapper input:focus {
    border-radius: 20px 20px 10px 10px!important;
}

.vue-simple-suggest.designed .suggestions {
    border-radius:0px 0px 20px 20px!important;
    min-height: 110px;
    padding-top: 30px!important;
    padding-bottom: 20px;
    z-index: 7!important;
    top:40px!important;
    border-top: 1px solid #e0e0e000 !important;
}

.vue-simple-suggest.designed .suggestions .suggest-item, .vue-simple-suggest.designed .suggestions .misc-item {
    padding: 10px 30px 10px!important;
}

.vue-simple-suggest.designed .suggestions .suggest-item.hover:hover {
    background-color: rgba(157, 160, 157, 0.63)!important;
    color: black!important
}

.vue-simple-suggest.designed .suggestions .suggest-item.selected {
    background-color: rgba(157, 160, 157, 0.63)!important;
    color: black!important
}

.form-control:focus{
    outline:0!important
}

.search-icon{
    position:absolute;
    z-index: 10;
    margin-left: 20px;
    font-size: 20px;
    margin-top: 12px;
    color:grey
}

</style>
