import Vue from 'vue'
import Vuex from 'vuex'

import orders from '@/store/modules/orders.js'
import session from '@/store/modules/session.js'
import productSearch from '@/store/modules/productSearch.js'
import product from '@/store/modules/product'
import colours from '@/store/modules/colours'


Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        session,
        orders,
        productSearch,
        product,
        colours
    }
})