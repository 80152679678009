var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":("modal-info-" + ('mod' + _vm.productId)),"title":'Mod. ' + _vm.productId,"hide-footer":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"vid":"previousProductId","name":"previousProductId","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"previousProductId"}},[_vm._v("Modelo Anterior")]),_c('b-form-input',{attrs:{"id":"previousProductId","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.mod_anterior),callback:function ($$v) {_vm.$set(_vm.productInfo, "mod_anterior", $$v)},expression:"productInfo.mod_anterior"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"vid":"productId","name":"productId","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"productId"}},[_vm._v("Modelo Actual")]),_c('b-form-input',{attrs:{"id":"productId","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.modelo),callback:function ($$v) {_vm.$set(_vm.productInfo, "modelo", $$v)},expression:"productInfo.modelo"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"vid":"type","name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"type"}},[_vm._v("Tipo")]),_c('b-form-input',{attrs:{"id":"type","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.tipo),callback:function ($$v) {_vm.$set(_vm.productInfo, "tipo", $$v)},expression:"productInfo.tipo"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"vid":"family","name":"family","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"family"}},[_vm._v("Familia")]),_c('b-form-input',{attrs:{"id":"family","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.familia),callback:function ($$v) {_vm.$set(_vm.productInfo, "familia", $$v)},expression:"productInfo.familia"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"vid":"sizes","name":"sizes","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"sizes"}},[_vm._v("Tallas")]),_c('b-form-input',{attrs:{"id":"sizes","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.tallas),callback:function ($$v) {_vm.$set(_vm.productInfo, "tallas", $$v)},expression:"productInfo.tallas"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"description","name":"description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"description"}},[_vm._v("Descripción")]),_c('b-form-input',{attrs:{"id":"description","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.descripcion),callback:function ($$v) {_vm.$set(_vm.productInfo, "descripcion", $$v)},expression:"productInfo.descripcion"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"cols":"8"}},[_c('ValidationProvider',{attrs:{"vid":"notes","name":"notes","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"notes"}},[_vm._v("Notas")]),_c('b-form-input',{attrs:{"id":"notes","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.descripcion2),callback:function ($$v) {_vm.$set(_vm.productInfo, "descripcion2", $$v)},expression:"productInfo.descripcion2"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"vid":"season","name":"season","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"season"}},[_vm._v("Temporada")]),_c('b-form-input',{attrs:{"id":"season","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.temporada),callback:function ($$v) {_vm.$set(_vm.productInfo, "temporada", $$v)},expression:"productInfo.temporada"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"vid":"year","name":"year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"year"}},[_vm._v("Año")]),_c('b-form-input',{attrs:{"id":"year","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.año),callback:function ($$v) {_vm.$set(_vm.productInfo, "año", $$v)},expression:"productInfo.año"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-card',{staticClass:"mt-3",attrs:{"title":"Marcas"}},[_c('ValidationProvider',{attrs:{"vid":"brand","name":"brand","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"brand"}},[_vm._v("1º Marca")]),_c('b-form-input',{attrs:{"id":"brand","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.marca),callback:function ($$v) {_vm.$set(_vm.productInfo, "marca", $$v)},expression:"productInfo.marca"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])}),_c('ValidationProvider',{attrs:{"vid":"brand-2","name":"brand-2","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"brand-2"}},[_vm._v("2º Marca")]),_c('b-form-input',{attrs:{"id":"brand-2","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.marca2),callback:function ($$v) {_vm.$set(_vm.productInfo, "marca2", $$v)},expression:"productInfo.marca2"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])}),_c('ValidationProvider',{attrs:{"vid":"brand-3","name":"brand-3","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"brand-3"}},[_vm._v("3º Marca")]),_c('b-form-input',{attrs:{"id":"brand-3","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.marca3),callback:function ($$v) {_vm.$set(_vm.productInfo, "marca3", $$v)},expression:"productInfo.marca3"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-card',{staticClass:"mt-3",attrs:{"title":"Fabricantes"}},[_c('ValidationProvider',{attrs:{"vid":"manufacturer","name":"manufacturer","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"manufacturer"}},[_vm._v("1º Fabricante")]),_c('b-form-input',{attrs:{"id":"manufacturer","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.fabricante1),callback:function ($$v) {_vm.$set(_vm.productInfo, "fabricante1", $$v)},expression:"productInfo.fabricante1"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])}),_c('ValidationProvider',{attrs:{"vid":"manufacturer-2","name":"manufacturer-2","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"manufacturer-2"}},[_vm._v("2º Fabricante")]),_c('b-form-input',{attrs:{"id":"manufacturer-2","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.fabricante2),callback:function ($$v) {_vm.$set(_vm.productInfo, "fabricante2", $$v)},expression:"productInfo.fabricante2"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-card',{staticClass:"mt-3",attrs:{"title":"Fabricantes"}},[_c('ValidationProvider',{attrs:{"vid":"manufacturer","name":"manufacturer","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"manufacturer"}},[_vm._v("Precio")]),_c('b-form-input',{attrs:{"id":"manufacturer","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.precio),callback:function ($$v) {_vm.$set(_vm.productInfo, "precio", $$v)},expression:"productInfo.precio"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])}),_c('ValidationProvider',{attrs:{"vid":"manufacturer-2","name":"manufacturer-2","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"input-field mt-3"},[_c('label',{staticClass:"pb-1",attrs:{"for":"manufacturer-2"}},[_vm._v("Precio Rebaja")]),_c('b-form-input',{attrs:{"id":"manufacturer-2","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.productInfo.precio_rebaja),callback:function ($$v) {_vm.$set(_vm.productInfo, "precio_rebaja", $$v)},expression:"productInfo.precio_rebaja"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }