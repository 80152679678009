import ApiService from '@/services/ApiService.js';

export default {
    state: {
        search: [],
        searchWords: '',
    },

    getters: {
        search: state => state.search,
        searchWords: state => state.searchWords
    },

    mutations: {

        /**
         * Set the state of the search
         *
         * @param {Object} state
         * @param {String} search
         *
         */
        MAKE_A_SEARCH(state, search) {
            state.search = search
        },

        /**
         * Set the state of shearched words
         *
         * @param {Object} context
         * @param {String} searchWords
         *
         */
        SET_SEARCH_WORDS(state, searchWords) {
            state.searchWords = searchWords
        }
    },

    actions: {

        /**
         * Update data from order details
         *
         * @param {Object} context
         * @param {String} search
         *
         * @return {Promise}
         */
        getProductsFromSearch(context, search) {
            context.commit('SET_SEARCH_WORDS', search)
            return new Promise((resolve, reject) => {
                ApiService.productSearch(search)
                    .then((response) => {
                        context.commit('MAKE_A_SEARCH', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
    }
}