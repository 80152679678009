<template>
    <b-row class="border mt-4 p-3 add-colour-row mx-auto">
      <b-col>
        <b-form-select
          class="mt-3 color-select"
          v-model="selected"
          :options="options"
        >
          <b-form-select-option
            value="null"
            disabled
          >
          Elige un color
        </b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col>
        <b-form-select
          class="mt-3 color-select"
          v-model="sm"
          :options="smOptions"
        >
          <b-form-select-option
            value="null"
            disabled
          >
            SM
          </b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col>
        <b-button
          @click="addProductStockColour()"
          title="Add colour"
          class="delete-button mt-2"
        >
          <b-badge
            class="add-button"
          >
            +
          </b-badge>
        </b-button>
      </b-col>
    </b-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'ProductAddColorComponent',
    data() {
      return {
        selected: null,
        sm: null,
        smOptions: [
          {value: 'si', text: 'si'},
          {value: 'no', text: 'no'}
        ]
      }
    },

    props : {
      productId: Number,
    },

    computed: {
      ...mapGetters(['colours']),

      options() {
        return this.colours.map((item)=> {
          return { value: item.id.toString().padStart(2, '0'), text: `${item.id} - ${item.color}` }
        })
      }
    },

    methods: {
      ...mapActions(['getAllColours', 'addColourToProductStock', 'getProductStock']),

      /**
       * Add colour to the product
       *
       */
      addProductStockColour() {
        let productId = this.productId
        let colour = this.selected
        let sm = this.sm

        if (sm === null || colour === null) {
          return alert('Puede que no hayas elegido un color o que no hayas elegido la opción sm')
        }

        this.$root.$emit('stockLoader', true)

        this.addColourToProductStock({ productId, colour, sm })
          .then((response) => {
            this.$root.$emit('colourAddedAlert', response, 'success')
            this.getProductStock(this.productId)
            this.$root.$emit('stockLoader', false)
          })
          .catch((error) => {
            this.$root.$emit('colourAddedAlert', error, 'danger')
            this.$root.$emit('stockLoader', false)
          })
        this.$root.$emit('showProductAddColourComponent', false)
      }
    },

    created() {
      this.getAllColours()
    }
}
</script>
<style>
.add-color-input {
  width: 20%
}
.add-colour-row {
  width: 40%;
  text-align: center;
}
.add-button {
  font-size: 15px
}
.add-button:hover {
  background-color: grey;
}
@media (min-width:0px) and (max-width: 575px) {
  .add-colour-row {
    width: 100%;
  }
  .color-select {
    width: 120px
  }
}
@media (min-width:576px) and (max-width: 769px) {
  .add-colour-row {
    width: 50%;
  }
  .color-select {
    width: 150px
  }
}
@media (min-width:770px) and (max-width: 1200px) {
  .add-colour-row {
    width: 55%;
  }
}

</style>