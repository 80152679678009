
import ApiService from '@/services/ApiService.js';

export default {
    state: {
        orders: [],
        orderInfo: [],
        orderStatus: [],
        orderDetails: [],
        orderBillingAdress: [],
        orderShippingAdress: [],
        sizes: ['unica','00', '0', '1', '2', '3','4', '5', '6', '7', '8', '9', '10', '12', '18', '24', '36'],
    },

    getters: {
        orders: state => state.orders,
        orderInfo: state => state.orderInfo,
        orderStatus: state => state.orderStatus,
        orderDetails: state => state.orderDetails,
        sizes: state => state.sizes,
        orderBillingAdress: state => state.orderBillingAdress,
        orderShippingAdress: state => state.orderShippingAdress
    },

    mutations: {

        /**
         * Set the state for orders
         *
         * @param {Object} state
         * @param {Object} orders
         *
        */
        GET_ALL_ORDERS(state, orders) {
            state.orders = orders
        },

        /**
         * Set the state for order info
         *
         * @param {Object} state
         * @param {Object} orderInfo
         *
        */
        SET_ORDER_INFO(state, orderInfo) {
            state.orderInfo = orderInfo
        },

        /**
         * Set state for order status
         *
         * @param {Object} state
         * @param {Object} orderStatus
         *
        */
        SET_ORDER_STATUS(state, orderStatus) {
            state.orderStatus = orderStatus
        },

        /**
         * Set state for order details
         *
         * @param {Object} state
         * @param {Object} orderDetails
         *
        */
        GET_ORDER_DETAILS(state, orderDetails) {
            state.orderDetails = orderDetails
        },

        /**
         * Set state for order billing adress
         *
         * @param {Object} state
         * @param {Object} orderBillingAdress
         *
        */
        GET_ORDER_BILLING_ADRESS(state, orderBillingAdress) {
            state.orderBillingAdress = orderBillingAdress
        },

        /**
         * Set state for order billing adress
         *
         * @param {Object} state
         * @param {Object} orderShippingAdress
         *
        */
        GET_ORDER_SHIPPING_ADRESS(state, orderShippingAdress) {
            state.orderShippingAdress = orderShippingAdress
        }
    },

    actions: {

        /**
         * Fetch all orders from ctworker
         *
         * @param {Object} context
         *
         * @return {Promise}
        */
        getAllOrders(context) {
            return new Promise((resolve, reject) => {
                ApiService.getAllOrders()
                    .then((response) => {
                        context.commit('GET_ALL_ORDERS', response.data)
                        resolve(response.data)
                    }).catch((error) => {
                        reject(error)
                    })
            })
        },

        /**
         * Fetch order info from ctworker
         *
         * @param {Object} context
         * @param {Number} orderNumber
         *
         * @return {Promise}
        */
        getOrderInfo(context, orderNumber) {
            return new Promise(() => {
                ApiService.getOrderInfo(orderNumber)
                    .then((response) => {
                        const status = response.data[0].estado
                        const STATUS_INFO = {
                            0: { value: 0, text: 'Preparándose', color: 'info' },
                            1: { value: 1, text: 'Enviado', color: 'info' },
                            2: { value: 2, text: 'Entregado', color: 'success' },
                            3: { value: 3, text: 'Cancelado', color: 'danger' },
                        }
                        const ORDER_STATUS =  STATUS_INFO[status]
                        context.commit('SET_ORDER_STATUS', ORDER_STATUS)
                        context.commit('SET_ORDER_INFO', response.data[0])
                    })
            })
        },

        /**
         * Fetch order details
         *
         * @param {Object} context
         * @param {Nubmer} orderNumber
         *
         * @return {Promise}
        */
        getOrderDetails(context, orderNumber) {
            return new Promise((resolve, reject) => {
                ApiService.getOrderDetails(orderNumber)
                    .then((response) => {
                        context.commit('GET_ORDER_DETAILS', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        /**
         * Fetch the billing adress from the order
         *
         * @param {Object} context
         * @param {Number} orderNumber
         *
         * @return {Promise}
        */
        getOrderBillingAdress(context, orderNumber) {
            return new Promise((resolve, reject) => {
                ApiService.getOrderBillingAdress(orderNumber)
                    .then((response) => {
                        context.commit('GET_ORDER_BILLING_ADRESS', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        /**
         * Fetch the shipping adress from the order
         *
         * @param {Object} context
         * @param {Number} orderNumber
         *
         * @return {Promise}
        */
        getOrderShippingAdress(context, orderNumber) {
            return new Promise((resolve, reject) => {
                ApiService.getOrderShippingAdress(orderNumber)
                    .then((response) => {
                        context.commit('GET_ORDER_SHIPPING_ADRESS', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        /**
         * Update data from order details
         *
         * @param {Object} context
         * @param {Object} data
         *
         * @return {Promise}
        */
        updateOrderDetails(context, data) {
            return new Promise((resolve, reject) => {
                ApiService.updateOrderDetails(data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        /**
         * Update order status
         *
         * @param {Object} context
         * @param {Object} orderStatus
         *
         * @return {Promise}
        */
        updateOrderStatus(context, orderStatus) {
            return new Promise((resolve, reject) => {
                ApiService.updateOrderStatus(orderStatus)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        /**
         * Delete certain color form product of the Order
         *
         * @param {Object} context
         * @param {Number} id
         *
         * @return {Promise}
        */
        deleteOrderProductColor(context, id) {
            return new Promise((resolve, reject) => {
                ApiService.deleteColorFromProduct(id)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
    }
}