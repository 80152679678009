import axios from 'axios'
import Store from '@/store/index'

const ApiService = axios.create({

    headers: {
        'Content-Type': 'application/json',
    },

    baseURL: process.env.VUE_APP_ENV == 'localhost' ? process.env.VUE_APP_URL_LOCALHOST : process.env.VUE_APP_URL_CTW
})

// The request interceptor ensures that we check for the token
// in local storage every time an ajax request is made
ApiService.interceptors.request.use(
    config => {
        const token = localStorage.getItem('ctWorker.jwt') || null;
        config.headers['Authorization'] = `Bearer ${ token }`

        return config
    },

    error => {
        return Promise.reject(error)
    }
)

// The response interceptor process the unauthorized responses
ApiService.interceptors.response.use(
    response => {
        return response;
    },

    error => {
        if (error.response.status === 401) {
            Store.dispatch('doLogOut')
            .then(() => {
                window.location.replace("/error");
            })
        }
        return Promise.reject(error)
    }
)

export default {

    /**
     * Log out user of the ct worker system
     *
     * @return {Promise}
     */
    logOut() {
        return ApiService.get('employeeLogout')
    },

    /**
     * Check the autentication in session
     *
     * @param {String} string
     *
     * @return {Promise}
     */
    generateBcrypPassword(string) {
        return ApiService.get('/generateBcrypPassword/' + string)
    },

    /**
     * Make a search in CTworker
     *
     * @param {String} search
     *
     * @return {Promise}
     */
    productSearch(search) {
        return ApiService.get('/getProductsFromSearch/' + search)
    },

    /**
     * Fetch all orders from ctworker
     *
     * @return {Promise}
     */
    getAllOrders() {
        return ApiService.get('/getAllOrders')
    },

    /**
    * Get order info from CTworker
    *
    * @param {Number} orderNumber
    *
    * @return {Promise}
    */
    getOrderInfo(orderNumber) {
        return ApiService.get('/getOrderInfo/' + orderNumber)
    },

    /**
     * get order products details CTworker
     *
     * @param {Number} orderNumber
     *
     * @return {Promise}
     */
    getOrderDetails(orderNumber) {
        return ApiService.get('/getOrderDetails/' + orderNumber)
    },

    /**
     * Fetch the billing adress from the order
     *
     * @param {Number} orderNumber
     *
     * @return {Promise}
     */
    getOrderBillingAdress(orderNumber) {
        return ApiService.get('/getOrderBillingAdress/' + orderNumber)
    },

    /**
     * Fetch the shipping adress from the order
     *
     * @param {Number} orderNumber
     *
     * @return {Promise}
     */
    getOrderShippingAdress(orderNumber) {
        return ApiService.get('/getOrderShippingAdress/' + orderNumber)

    },

    /**
     * Get product stock in every size
     *
     * @param {Number} product
     *
     * @return {Promise}
     */
    getProductStock(product) {
        return ApiService.get('/getProductSizes/' + product)
    },

    /**
     * Log user into the CT worker system
     *
     * @param {Object} user
     *
     * @return {Promise}
     */
    login(user) {
        return ApiService.post('login', user)
    },

    /**
     * Update stock in every size and color from a product
     *
     * @param {Object} product
     *
     * @return {Promise}
     */
    updateProductStock(product) {
        return ApiService.put('/updateProductStock/', { product })
    },

    /**
     * Update order status
     *
     * @param {Object} orderStatus
     *
     * @return {Promise}
     */
    updateOrderStatus(orderStatus) {
        return ApiService.put('/updateOrderStatus', { orderStatus })
    },

    /**
     * Update data from order details
     *
     * @param {Object} data
     *
     * @return {Promise}
     */
    updateOrderDetails(data) {
        return ApiService.put('/updateOrderDetails', { data })
    },

    /**
     * Get all the colours from database
     *
     * @return {Promise}
     */
    getAllColours() {
        return ApiService.get('/getAllColours')
    },

    /**
     * Add colour to the product
     *
     * @param {Object} product
     *
     * @return {Promise}
     */
    addColourToProductStock(product) {
        return ApiService.post('/addColourToProductStock', { product })
    },

    /**
     * Delete colour from product stock
     *
     * @param {Object} productColourId
     *
     * @return {Promise}
     */
    deleteColorFromProductStock(productColourId) {
        return ApiService.delete('/deleteColorFromProductStock/' + productColourId)
    },

    /**
     * Delete certain color form product of the Order
     *
     * @param {String} id
     *
     * @return {Promise}
     */
    deleteColorFromProduct(id) {
        return ApiService.delete('/deleteColorFromProduct/' + id)
    },

    /**
     * Get the season stock products
     *
     * @param {String} season
     *
     * @return {Promise}
     */
    getSeasonProductStock(season, year, brand) {
        return ApiService.get('/getProductStockBySeason/' + season + '/' + year + '/' + brand)
    }
}
