<template>
    <b-container fluid>
      <h2 class="text-center my-5 w-50 border mx-auto">{{ capitalizeFirstLetter(this.$route.params.brand) + ' ' + capitalizeFirstLetter(this.$route.params.season) + ' ' + capitalizeFirstLetter(this.$route.params.year)}}</h2>
        <table class="table table-bordered mx-auto">
          <thead>
            <tr class="text-center">
              <th scope="col">Modelo</th>
              <th scope="col">Color</th>
              <th scope="col">Tipo</th>
              <th scope="col">Única</th>
              <th scope="col">00 m/ 1 a</th>
              <th scope="col">0 m/ 2 a</th>
              <th scope="col">1 m/ 3 a</th>
              <th scope="col">3 m/ 4 a</th>
              <th scope="col">6 m/ 5 a</th>
              <th scope="col">9 m/ 6 a</th>
              <th scope="col">12 m/ 7 a</th>
              <th scope="col">18 m/ 8 a</th>
              <th scope="col">24 m/ 10 a</th>
              <th scope="col">36 m/ 12 a</th>
              <th scope="col">Precio </th>
              <th scope="col">Precio Rebaja</th>
            </tr>
          </thead>
          <tbody class="text-center" v-for="(info, Index) in seasonProductStock" :key="Index">
            <tr v-if="info.tallas.includes('meses') || info.tallas.includes('Única')">
              <td>{{ info.modelo }}</td>
              <td>{{ info.id_color }}</td>
              <td>{{ info.tipo }}</td>
              <td>{{ info.talla_unica === 0 ? '' : info.talla_unica }}</td>
              <td>{{ info.talla_00 === 0 ? '' : info.talla_00 }}</td>
              <td>{{ info.talla_0 === 0 ? '' : info.talla_0 }}</td>
              <td>{{ info.talla_1 === 0 ? '' : info.talla_1 }}</td>
              <td>{{ info.talla_3 === 0 ? '' : info.talla_3 }}</td>
              <td>{{ info.talla_6 === 0 ? '' : info.talla_6 }}</td>
              <td>{{ info.talla_9 === 0 ? '' : info.talla_9 }}</td>
              <td>{{ info.talla_12 === 0 ? '' : info.talla_12 }}</td>
              <td>{{ info.talla_18 === 0 ? '' : info.talla_18 }}</td>
              <td>{{ info.talla_24 === 0 ? '' : info.talla_24 }}</td>
              <td>{{ info.talla_36 === 0 ? '' : info.talla_36 }}</td>
              <td>{{ info.precio }}</td>
              <td>{{ info.precio_rebaja }}</td>


            </tr>
            <tr v-else>
              <td>{{ info.modelo }} (años)</td>
              <td>{{ info.id_color }}</td>
              <td>{{ info.tipo }}</td>
              <td></td>
              <td>{{ info.talla_1 === 0 ? '' : info.talla_1 }}</td>
              <td>{{ info.talla_2 === 0 ? '' : info.talla_2 }}</td>
              <td>{{ info.talla_3 === 0 ? '' : info.talla_3 }}</td>
              <td>{{ info.talla_4 === 0 ? '' : info.talla_4 }}</td>
              <td>{{ info.talla_5 === 0 ? '' : info.talla_5 }}</td>
              <td>{{ info.talla_6 === 0 ? '' : info.talla_6 }}</td>
              <td>{{ info.talla_7 === 0 ? '' : info.talla_7 }}</td>
              <td>{{ info.talla_8 === 0 ? '' : info.talla_8 }}</td>
              <td>{{ info.talla_10 === 0 ? '' : info.talla_10 }}</td>
              <td>{{ info.talla_12 === 0 ? '' : info.talla_12 }}</td>
              <td>{{ info.precio }}</td>
              <td>{{ info.precio_rebaja }}</td>

            </tr>
          </tbody>
        </table>
    </b-container>
  </template>
  <script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
      name: 'ProductStockTableComponent',
      data() {
        return {
          productStock: []
        }
      },
      computed:{
        ...mapGetters(['seasonProductStock'])
      },
      methods: {
        ...mapActions(['getSeasonProductStock']),

        capitalizeFirstLetter(word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
      },
      created() {
        this.getSeasonProductStock([this.$route.params.season, this.$route.params.year, this.$route.params.brand])
      }
    }
  </script>
