<template>
    <b-container>
        <b-overlay :show="show" rounded="sm">
            <b-row v-for="(info, Index) in productStock" :key="Index">
                <b-col>
                    <b-badge
                        :hidden="Index != 0"
                        :id="Index"
                    >
                        color
                    </b-badge>
                    <p class="text-center mt-2">
                        {{ info.sm == 'si' ? 'SM ' + info.id_color : 'C/' + info.id_color }}
                    </p>
                </b-col>
                <b-col
                    v-for="size in sizes"
                    :key="size"
                    class="mx-auto"
                >
                    <b-badge
                        :hidden="Index != 0"
                        :id="Index"
                    >
                        {{ size == 'unica' ? 'Única' : size + ' m' }}
                    </b-badge>
                    <b-form-input
                        class="stock-input mb-2"
                        v-model="info['talla_' + size]"
                        @change="updateProductStockInfo(size, info['talla_' + size], info.color)"
                    >
                    </b-form-input>
                </b-col>
                <b-col class="button-column">
                    <b-button
                        title="Delete"
                        :class="Index == 0 ? 'mt-34px delete-button' : 'mt-1 delete-button'"
                        @click="deleteProductColor(info.id, info.modelo)"
                    >
                        <b-icon
                            icon="trash-fill"
                            aria-hidden="true"
                            class="trash-icon"
                        >
                        </b-icon>
                    </b-button>
                </b-col>
            </b-row>
        </b-overlay>
    </b-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'SizesStockComponent',
    data() {
        return {
            show: false,
            sm: '<span class="text-success"> SM</span>'
        }
    },
    props : {
        sizes:Array,
    },

    computed: {
        ...mapGetters(['productStock']),
    },

    methods: {
        ...mapActions(['updateProductStockVuex', 'deleteColorFromProductStock', 'getProductStock']),

        /**
         * Update product stock and save the info in database
         *
         * @param size
         * @param quantity
         * @param color
         *
         */
        updateProductStockInfo(size, quantity, color) {
            const PRODUCT = {
                size: size,
                quantity: quantity,
                color: color
            }
            this.updateProductStockVuex(PRODUCT)
        },

        /**
         * Delete product color from stock
         *
         * @param productColourId
         * @param productId
         *
         * @response
         */
        deleteProductColor(productColourId, productId) {
            this.show = true
            this.deleteColorFromProductStock(productColourId)
                .then((response) => {
                    this.show = false
                    this.getProductStock(productId)
                    this.$root.$emit('colourAddedAlert', response, 'success')
                })
                .catch((error) => {
                    this.show = false
                    this.getProductStock(productId)
                    this.$root.$emit('colourAddedAlert', error.message, 'danger')
                })
        }
    },

    mounted() {
        this.$root.$on('stockLoader', (boolean) => {
            this.show = boolean
        })
    }
}
</script>
<style>
.badge {
    background-color: green;
    width: 100%;
    margin-bottom: 10px
}
.mt-37px {
    margin-top: 37px
}
.mt-34px {
    margin-top: 34px
}
.green-button {
    float:right;
    background-color: green;
}
.button-column {
    max-width:40px;
    padding:0px
}
</style>
