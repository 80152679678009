<template>
    <div>
        <b-modal
            :id="`modal-stock-${'mod' + productId}`"
            :title="'Mod. ' + productId"
            hide-footer
        >
            <div v-if="sizesType.includes('meses')">
                <SizesStockComponent
                    :sizes="monthSizes"
                >
                </SizesStockComponent>
            </div>
            <div v-if="sizesType.includes('años')">
                <SizesStockComponent
                    :sizes="yearSizes"
                >
                </SizesStockComponent>
            </div>
            <div v-if="sizesType.includes('Única')">
                <SizesStockComponent
                    :sizes="uniqueSize"
                >
                </SizesStockComponent>
            </div>
            <ProductAddColorComponent
                :productId="productId"
                v-show="show"
            >
            </ProductAddColorComponent>
            <b-button
                @click="updateData()"
                class="green-button mr-3"
                variant="success"
            >
                Actualizar Stock
            </b-button>
            <b-button
                class="green-button"
                variant="success"
                @click="show= show == true ? false: true"
            >
                Añadir color
            </b-button>
            <b-alert
                :variant="variant"
                dismissible
                fade
                :show="dismissCountDown"
                @dismissed="dismissCountDown=0"
                class = "text-center w-75"
            >
                {{ message }}
            </b-alert>
        </b-modal>
    </div>
</template>
<script>
import SizesStockComponent from "@/components/productComponents/SizesStockComponent.vue"
import ProductAddColorComponent from "@/components/productComponents/ProductAddColorComponent.vue"

import { mapActions } from 'vuex'

export default {
    name: 'InputSearchComponent',
    data() {
        return {
            monthSizes: ['00', '0', '1', '3', '6', '9', '12', '18', '24', '36', '48'],
            yearSizes: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '12', '14'],
            uniqueSize: ['unica'],
            message: '',
            variant: '',
            dismissCountDown: 7,
            dismissSecs: 7,
            show: false
        }
    },

    components: {
        SizesStockComponent,
        ProductAddColorComponent
    },

    props : {
        productId:Number,
        sizesType: String
    },

    methods: {
        ...mapActions(['updateProductStock', 'getProductStock']),

        /**
         * Update stock product data
         *
         */
        updateData() {
            this.updateProductStock()
                .then((response) => {
                    this.variant = 'success'
                    this.dismissCountDown = this.dismissSecs
                    this.message = response
                    this.getProductStock(this.productId)
                }).catch((error) => {
                    this.variant = 'danger'
                    this.dismissCountDown = this.dismissSecs
                    this.message = error
                })
        },
    },
    mounted() {
        this.$root.$on('showProductAddColourComponent', (show) => {
            this.show = show
        })
        this.$root.$on('colourAddedAlert', (response, variant) => {
            this.variant = variant
            this.dismissCountDown = this.dismissSecs
            this.message = response
        })
    }
}
</script>
<style>
.alert .close {
    font-size: 20px!important;
    float:right;
    visibility: hidden;
}

.modal-content .close {
    border: none;
    background-color: transparent;
    font-size: 40px
}
.modal-dialog {
    max-width: 100%
}
.modal-content {
    width: 100%;
    max-width: 1200px;
    margin-left:auto;
    margin-right: auto;
}
.color-text {
    width:5%
}
.sizes-list {
    display: flex!important;
}
.green-button {
    float:right;
    background-color: green;
    margin:0.5rem
}
</style>