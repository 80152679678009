<template>
  <div class="text-center">
    <h4 class="mt-5">Creador de Hash</h4>
    <b-card class="w-50 mx-auto mt-5">
      <b-input-group prepend="Introducir cadena" class="mb-2">
        <b-form-input v-model="string" aria-label="First name" @keyup.enter="hashPassword()"></b-form-input>
      </b-input-group>
      <b-card class="mt-5">
        <h6>{{ hash }}</h6>
      </b-card>
    </b-card>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'PaswordHashComponent',
  data() {
    return {
      string: '',
      hash: ''
    }
  },
  methods:{
    ...mapActions(['stringEncryption']),
    hashPassword() {
      this.stringEncryption(this.string)
        .then((response) => {
          this.hash = response
        })
        .catch((error) => {
          alert(error)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
